<template>
  <div class="means">
    <PageTopNav
      :pageNavShow="false"
      :addShow="false"
      :tabList="tabList"
      @changeTab="changeTab"
    >
    </PageTopNav>
    <div class="outDiv">
      <div class="mains">
        <div class="lists" v-if="meansList.length">
          <MyTable
            class="animated fadeInUp"
            :tableData="meansList"
            :configTable="configTable"
            @handleOut="setWarehouse"
          ></MyTable>
        </div>
        <div class="lists" v-else>
          <MyNothing></MyNothing>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          v-if="meansList.length"
        >
        </el-pagination>
      </div>
    </div>
    <MyNav ref="MyNav"></MyNav>
    <el-dialog
      class="small-pop"
      title=" "
      :visible.sync="dialogVisible"
      width="662px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pop-small">
        <div class="ware-title">
          <div class="left-line"></div>
          <span>库存出库</span>
        </div>
        <el-form
          class="forms"
          ref="batchForm"
          :model="batchForm"
          :rules="batchRules"
          hide-required-asterisk
        >
          <el-form-item label="数量" :label-width="formLabelWidth" prop="num">
            <el-input
              type="number"
              v-model.number="batchForm.num"
              placeholder="请输入数量"
              @input="inputNumber"
            ></el-input>
          </el-form-item>

          <el-form-item label=" " class="dialog-footer">
            <el-button
              class="ok-button"
              type="primary"
              @click="okWarehouse('batchForm')"
            >
              确 定
            </el-button>
            <el-button class="no-button" @click="noWarehouse('batchForm')">
              取 消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import { getStockListApi, postStockOutApi } from "@/request/api";
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      // 分页
      total: 0,
      per_page: 10,
      current_page: 1,
      tabList: [],
      meansList: [],
      configTable: [
        {
          prop: "product_name",
          label: "产品",
          width: "260",
        },
        {
          prop: "unit",
          label: "产品单位",
          width: "260",
        },
        {
          prop: "model",
          label: "产品规格",
          width: "260",
        },

        {
          prop: "num",
          label: "加工数量",
          width: "260",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "",
        },

        {
          prop: "",
          label: "操作",
          width: "160",
          outType: true,
        },
      ],
      dialogVisible: false,
      batchForm: {
        num: "",
        stock_id: "",
      },
      batchRules: {
        num: [{ required: true, message: "请输入数量", trigger: "blur" }],
      },
      formLabelWidth: "40px",
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.batchForm = {
          num: "",
          stock_id: "",
        };
      }
    },
  },
  created() {
    this.getStockList();
  },
  methods: {
    inputNumber() {
      if (this.batchForm.num <= 0) this.batchForm.num = "";
    },

    handleCurrentChange(val) {
      this.current_page = val;
      this.getStockList();
    },
    async getStockList() {
      showFullScreenLoading();
      const { code, results } = await getStockListApi({
        pn: this.current_page,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.page_size;
        this.current_page = results.page_num;
        this.meansList = results.data;
      }
      tryHideFullScreenLoading();
    },
    changeTab() {
      this.current_page = 1;
      this.getStockList();
    },
    noWarehouse(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    okWarehouse(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addStockOut();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async addStockOut() {
      const { code } = await postStockOutApi(this.batchForm);
      if (code === 0) {
        this.getStockList();
        this.$notify({
          title: "提示",
          message: "添加成功",
          type: "success",
        });
      }
    },
    setWarehouse({ stock_id, num }) {
      if (!num) {
        this.$notify.error({
          title: "错误",
          message: "库存不足",
        });
        return;
      }
      this.batchForm.stock_id = stock_id;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.means {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  .outDiv {
    padding: 0 48px;
    .mains {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      background-color: #0e2138;
      padding: 22px 49px;

      .lists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .small-pop {
    /deep/.el-dialog {
      width: 662px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;
      align-items: center;
      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      display: flex;
      padding: 0 62px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  /deep/.uploadCover {
    width: 158px;
    height: 105px;
    display: flex;
    .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 105px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 158px;
      height: 105px;
    }
    &.active {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
